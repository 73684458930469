import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Hero from '../Hero'
import CTA from './partials/CTA'

import data from '../../temp-configs/home-data'

import styles from './HeroSection.module.scss'

const CTAList = ({ ctas }) => {
    return (
        <div className={styles.ctaList}>
            {ctas.map((cta, index) => {
                return (
                    <CTA
                        key={`cta-${cta.link}`}
                        index={index}
                        label={cta.label}
                        link={cta.link}
                        selector={cta.selector}
                    />
                )
            })}
        </div>
    )
}

CTAList.propTypes = {
    ctas: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string
        })
    ).isRequired
}

const HeroSection = () => {
    const { title, description, ctas } = data

    return (
        <Hero className={styles.homeHero}>
            <div className={styles.heroContainer}>
                <div>
                    <h1 className={classNames('t-heading1', styles.heading)}>
                        {title}
                    </h1>
                    <div className={styles.descriptionContainer}>
                        <h3 className={classNames('t-heading3', styles.description)}>
                            {description}
                        </h3>
                    </div>
                </div>
                <CTAList ctas={ctas} />
            </div>
        </Hero>
    )
}

export default HeroSection
