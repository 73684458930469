import React from 'react'
import classNames from 'classnames'

import styles from '../HeroSection.module.scss'

/**
 * Only shows the first CTA on mobile, on tablet onwards shows all
 */
const CTA = ({ index, label, link, selector }) => {
    /**
     * Add the hide class utility to all ctas after first one
     */
    const buttonClasses = classNames(styles.button, {
        'u-hidden--md-down': index > 1
    })

    return (
        <a className={buttonClasses} href={link} data-selector={selector}>
            {label}
        </a>
    )
}

export default CTA
